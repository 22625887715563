import { render, staticRenderFns } from "./product-lofInfo.vue?vue&type=template&id=4f70a4d8&scoped=true&"
import script from "./product-lofInfo.vue?vue&type=script&lang=js&"
export * from "./product-lofInfo.vue?vue&type=script&lang=js&"
import style0 from "./product-lofInfo.vue?vue&type=style&index=0&id=4f70a4d8&prod&lang=css&"
import style1 from "./product-lofInfo.vue?vue&type=style&index=1&id=4f70a4d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f70a4d8",
  null
  
)

export default component.exports